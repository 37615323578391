.grid-col {
    padding: 0.8rem !important;
    background:$body-bg;
	border-radius:$radius;
    // box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1); 

 
}

.row.grid {
    margin-bottom: 1.5rem;
    text-align: center;
}

.row.grid .grid-col:first-child{
    text-align: left;
}




