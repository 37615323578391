/* Editable */

/* #preloader{
	background-color: #fff;
	padding: 0;
	margin: 0;
	height: 100%;
    position: fixed;
    z-index: 99999;
    width: 100%;
	display:flex;
	align-items: center;
	justify-content: center;
	
	/* .lds-ripple {
	  display: inline-block;
	  position: relative;
	  width: 80px;
	  height: 80px;
	}
	.lds-ripple div {
	  position: absolute;
	  border: 4px solid var(--primary);
	  opacity: 1;
	  border-radius: 50%;
	  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	.lds-ripple div:nth-child(2) {
	  animation-delay: -0.5s;
	}
	@keyframes lds-ripple {
	  0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	  }
	  100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	  }
	}
}


[data-theme-version="dark"] {
	#preloader{
		background-color: $d-bg;
	}
} */

/* .loading span {
  position:relative;
  z-index:999;
  color:#fff;
}
.loading {
	width:150px;
	  text-align:center;
	  line-height:50px;
	  position:absolute;
	  left:0;right:0;top:50%;
	  margin:auto;
	  transform:translateY(-50%);
	span {
		position: relative;
		z-index: 999;
		color: #fff;
	}
	&:before {
		content: '';
		background: #61bdb6;
		width: 128px;
		height: 36px;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		animation: 2s loadingBefore infinite ease-in-out;
	}
	&:after {
		content: '';
		background: var(--primary);
		width: 14px;
		height: 60px;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		opacity: .5;
		animation: 2s loadingAfter infinite ease-in-out;
	}
}
@keyframes loadingAfter {
  0%   {transform:translateX(-50px);}
  50%  {transform:translateX(50px);}
  100% {transform:translateX(-50px);}
} */


/*.loader {
	width: 150px;
	height: 150px;
	margin: 0 auto;
	position: relative;
	.ball {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		box-shadow: 0 0 10px rgba(0,0,0,0.3);
		position: absolute;
		transition: all 0.8s ease-in-out;
	}
	#left {
		background-color: var(--primary);
		top: 53px;
		right: 0px;
		animation: left 2s infinite;
	}
	#right {
		background-color:var(--secondary);
		top: 53px;
		animation: right 2s infinite;
	}
	#up {
		background-color:$info;
		top: 0;
		left: 53px;
		animation: up 2s infinite;
	}
	#down {
		background-color: rgba(232, 36, 6,0.9);
		bottom: 0;
		left: 53px;
		animation: down 2s infinite;
	}
}
@keyframes left {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(-110px);
	}
	100% {
		transform: translateX(0px);
	}
}
@keyframes right {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(110px);
	}
	100% {
		transform: translateX(0px);
	}
}
@keyframes up {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(110px);
	}
	100% {
		transform: translateY(0px);
	}
}
@keyframes down {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-110px);
	}
	100% {
		transform: translateY(0px);
	}
}


}*/



#preloader {
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 999999;
	background-color: #e6ebee;
	
  .loader {
    margin: auto;
    width: 1rem;
    height: 1rem;
    border: 3px solid var(--primary);
   
    animation: loader 6s infinite ease-in-out;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg) scale(1);
  }
  
  50% {
    transform: rotate(720deg) scale(5);
  }
  
  100% {
    transform: rotate(0deg) scale(1);
  }
}
