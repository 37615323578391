-color

//heart Blast Effect

.heart-blast {
    background-position: -105rem 0!important;
    transition: background 1s steps(28);
}

.heart {
    width   	: 3.75rem;
    height  	: 3.75rem;
    display 	: inline-block;
    background  : url('../images/like.png');
    cursor      : pointer;
    margin      : -1.5625rem -0.9375rem;
}



// -------------------------
//     Dashboard
// -------------------------

.heading{
    font-size: 1.375rem;
    font-weight: 600;
	line-height: 1.5;
    margin: 7.5px 0;
}
.heading-lg{
    font-size: 1.5rem;
   
}
.bootstrap-select.image-select.default-select.dashboard-select{  
    width: 8.75rem ;
    height: 2.5rem;
	border-radius:$radius;
	background: #F3F3F3;
  
    .btn{
		padding-left: 1.125rem;
		padding-right: 1rem;
		line-height: 2.3rem;
        height: 100%;
        font-size: 0.875rem;
        padding-top: 0;
        padding-bottom: 0;
        @include respond('phone'){
            font-size: 0.875rem;
            font-weight: 500;
        }
    }
    .dropdown-menu{
        width: 100%;
    }
}
.subtitle{
    font-size: 2.25rem;
   
}
.default-select {
    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0;
        vertical-align: unset;
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border-top: 2px solid #666;
        border-right: 2px solid #666;
        transform: rotate(135deg);
        border-bottom: none;
        border-left: none;
        margin: 0;
        padding: 0;
        margin-bottom: 0.25rem;
    }
}
.default-select.dropup  .dropdown-toggle::after {
    transform: rotate(-45deg);
    margin-bottom: 0;
}
.input-group.search-area {
    border-radius: 0.625rem;
    overflow: hidden;
    height: 3rem;
    border: 1px solid $b-color;
    line-height: 1;
    .form-control{
        line-height: 1; 
        border-radius: 0 !important;
		box-shadow:none;
		height: 3rem;
		padding: 0px 12px;
    }
    .input-group-text {
        border-radius: 0!important;
        border: none;
		height: 3rem;
		padding-right:5px;
    }
   
}
.countrypicker{
    .dropdown-toggle::after{
        content: '';
        display: none;
    }   
} 
.form-select{
    padding: 0;

}


// ----------------
//     country-picker
// --------------

.bootstrap-select.countrypicker{
    background-color: $white;
    border-radius: $radius;
    margin-right: 1.125rem;
    border: 1px solid $b-color;
    @include respond('tab-land'){
        display: none;
    }
    .inline-flag {
     
        margin-top: 5px;
    }
    button.btn{
        border: none;
        line-height: 25px;
        height: 3rem;
        padding-left: 0.875rem;
    }
	.filter-option-inner-inner{
		margin-right: 14px;
		.inline-flag{
			margin-top: 4px;
			transform: scale(1.3);
		}
	}
    .dropdown-menu.show {
        background-color: transparent;
    }
}
.bootstrap-select.countrypicker .dropdown-menu.inner.show{
	height: 20rem;
    width: 10rem;
	overflow-y: scroll !important;
	background-color: $white;
    overflow-x: hidden !important;
    &::-webkit-scrollbar{
        width: 15px;
    }
    &::-webkit-scrollbar-track{
        background-color: $body-bg;
    }
    &::-webkit-scrollbar-thumb{
        background-color: var(--primary);
    }

}


// ------calender-------
.page-titles{
    .dz-calender{
        width: 18rem;
        align-items: center;
        position: relative;
        flex-wrap: nowrap;
        justify-content: flex-end;
        span.input-group-addon {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: $radius;
            border-top-right-radius: $radius !important;
            border-bottom-right-radius: $radius !important;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--rgba-primary-1);
			margin-right: 0.425rem;
        }
        input.form-control{
            border: none;
            background-color: transparent;
            line-height: 1.4;
            padding-top: 0;
            padding-bottom: 0;
            cursor: pointer;
			box-shadow:none;
			padding-left: 8px;
			font-size: 13px;
			font-weight: 500;
        }
        .dropdown-menu{
            width: 20rem;
        }
        &::before{
            content: '';
            position: absolute;
            top: 30%;
            left: 95%;
            width: 0.5rem;
            height: 0.5rem;
            border-top: 2px solid var(--primary);
            border-right: 2px solid var(--primary);
            transform: rotate(135deg) translateY(-50%);
            border-bottom: none;
            border-left: none;
            margin: 0;
            padding: 0;
            margin-bottom: 0.25rem
        }
        @include respond('phone-land'){
            width: 14rem;
            display: none;
        }
      
    }
}
.datepicker.datepicker-dropdown{
    width: 20rem;
    margin-top: 1rem;
    overflow: hidden;
    border-radius: 0.625rem;
	padding:10px;
    .datepicker-days{
        .table-condensed{
            td.day{
                font-size: 0.938rem;
                position: relative;
            }
            td.today.day{
                background-color: var(--rgba-primary-1);
                background-image: none;
            }
            td.today.active.day{
                background-color: var(--primary);
                &:hover{
                    background-color: var(--primary);
                }
              
            }
			tr{
				th{
					height: 40px;
					width: 40px;
				}
			}
        }
    }
    
}
.table-condensed {
    width: 100%;
}
//----- breadcrumb--
.guest-calendar {
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    
    .cale-icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: $radius;
        background-color: var(--rgba-primary-1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.625rem;
    }
    
}
.page-titles{
    #collapseOne {
        position: absolute;
        top: 95%;
        width: 25.625rem;
        right: 0;
        background-color: $white;
    }
}

// -------btn--
.dropdown{
    .btn.tp-btn.btn-primary{
        svg{
            path{
                fill: var(--primary);;
            }
        }
        &:hover{
            svg{
                path{
                    fill: #ffffff;
                }
            }
        }
    }
}
.compose-btn{
    .btn.btn-secondary{
		font-size:14px;
		font-weight:600;
        @include custommq($max:25rem){
            padding: 0.625rem 0.5rem;
        }
    }
	@include respond ('phone'){
		margin-bottom:14px;
	}
}



// -----sidebar--

.support-box {
    background-color: var(--primary);
    padding: 1.5rem;
    border-radius: $radius;
    position: relative;
    margin: 0.875rem 1.5rem;
	margin-bottom: 30px;
	overflow: hidden;
    .media {
      
        position: absolute;
        top: 15px; 
        right: 15px;
    }
    .info {
        h3{
            color: $white;
        }
    }
    &::before{
        content: '';
        display: block;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
		top: 40%;
		right: -72px;
		width: 10.625rem;
		height: 10.625rem;
        border-radius: 50%;

    }
}


// --------.card-box-------

.card{
    .card-box-icon {
        width:  4.5rem;
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #EEEEEE;
        border-radius: $radius;
       
    }
    .chart-num{
        
        h2{
            font-size: 2.25rem;
            margin-bottom: 0;
            line-height: 1;
        }
    
        p{
            font-size: 1.063rem;
            margin-bottom: 0;
        }
    }
    &:hover{
        .card-box-icon {
         animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        }
    }
}
.counter{
    .chart-num{
        margin-top: 0.375rem;
    }
	.card-body{
		padding:1.563rem;
		.card-box-icon{
			margin-right:1.25rem;
		}
	}
}
// -----pie-chart---

.color-picker {
    display: flex;
    justify-content: space-between;
}

// -------Statistic- chart--
.statistic {
    background-color: var(--primary);
}
.statistic-content{
	padding: 1.5rem 1.875rem;
    padding-bottom: 0;
	padding-left: 0;
	@include respond ('tab-land'){
		padding-left:1.875rem;
		padding-bottom: 1.25rem;
	}
    .default-select{
        border: none;
        color: $bg-color;
       
    }
    .bootstrap-select .btn{
        color: $white;
        border-color: var(--primary);
    }
    .default-select{
        background-image: none;
        position: relative;
        option{
            color: $main-color;
        }
    }
    .default-select .dropdown-toggle::after{
        border-color: $white;
    }
    .statistic-toggle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond('tab-land'){
            justify-content: flex-start;
			
        }
    }
	@include respond ('phone'){
		border-top:1px solid rgba(255,255,255,0.3);
		padding-bottom: 14px;
	}
}

.primary-light{
    background-color: rgba(255,255,255,0.1) !important;
    svg{
        g{
            circle{
            fill: $white;
            }
        }
    }
}
.card.expense{
    background-color: rgba(255,255,255,0.1)!important;
    .students1{
        .content{
            color: $white;
            span{
                margin-bottom: 0.5rem;
                display: block;
            }
            h2{
                font-size: 1.375rem;
            }
            h5{
                font-size: 1.125rem;
				margin-bottom: 0;
            }
            h5,h2{
                color: $white;
            }
            .up{
                color: #ffd125;
				
            }
        }
    }
}

// ---chackbox-----
.round {
    margin-right: 1.5rem;
    position: relative;
    padding-left: 2.2rem;
    cursor: pointer;
    font-size: 0.875rem;
    user-select: none;
    input{
        position: absolute;
        top: 0;
        left: 0;
        height: 1.25rem;
        width: 6.25rem;
        background-color: $bg-color;
        opacity: 0;
      }
    span{
        color: $main-color;
    }
    .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        background-color: $bg-color;
        border-radius: 50%;
        border: 3px solid var(--primary);
        padding: 2px;
        margin: 0;
      }
      .checkmark2 {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 1.35rem;
        width: 1.35rem;
        background-color: $bg-color;
        border-radius: 50%;
        border: 2px solid var(--primary);
        padding: 0.2rem;
      }
      
}
.round.weekly{
    .checkmark {
        border: 3px solid var(--secondary);
    }
}
  .round:hover input ~ .checkmark {
    background-color: $bg-color;
  }
  
  .round input:checked ~ .checkmark {
    background-color: $bg-color;
    border: 2px solid var(--primary);
  }
  .round.weekly input:checked ~ .checkmark {
    background-color: $bg-color;
    border: 2px solid var(--secondary);
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  

  .round input:checked ~ .checkmark:after {
    display: block;
  }
  .round.weekly input:checked ~ .checkmark:after {
    display: block;
    background-color:var(--secondary) ;
  }
  .round .checkmark:after {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    border-radius: 50%;
    position: relative;
  }
  .round.disabled {
    opacity: 1;
}
  
// ------chack---
.toggle-btn {
    margin-right: 1.8rem;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    font-size: 0.875rem;
    user-select: none;
    input{
        position: absolute;
        top: 0;
        left: 0;
        height: 1.25rem;
        width: 6.25rem;
        background-color: $bg-color;
        opacity: 0;
      }
    span{
        color: $bg-color;
    }
    h4{
        color: $bg-color;
    }
    .check {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 22px;
        width: 22px;
        background-color: var(--primary);
        border-radius: 50%;
        border: 3px solid $white;
        padding:2px;
      }
      
}

  .toggle-btn input:checked ~ .check {
    background-color: var(--primary);
    border: 3px solid $white;
  }
  
  .check:after {
    content: "";
    position: absolute;
    display: none;
  }
  

  .toggle-btn input:checked ~ .check:after {
    display: block;
  }
  .toggle-btn .check:after {
    width: 82%;
    height: 82%;
    background-color: $white;
    border-radius: 50%;
	top: 1px;
    left: 1px;
    position: relative;
  }
  .toggle-btn.expense{
        .check {
            border: 3px solid $yellow;
        }
    } 

  .toggle-btn.expense input:checked ~ .check{
    background-color: var(--primary);
    border: 3px solid $yellow;
  }
  .toggle-btn.expense .check:after{
    background-color: $yellow;
  }

// ------porgrass-bar---
  
  .progressbar{
      box-shadow: none;
  
}
.expense{
	.students1{
		padding: 0 10px;
	}
}
// ------contact--
#wallet-bar {
    background-color:$bg-color;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    border: 1px solid #CEDDE5;
    overflow: hidden;
	&.dlab-scroll{
		overflow-y:scroll;
	}
	@include custommq($max:105rem){
		display:none;
	}
}

.contacts{
    box-shadow: none;
    .add{
        background-color: var(--primary);
        display: block;
        width: 3rem;
        height: 3rem;
        border-radius: $radius;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.student{
	position:relative;
	margin: 0;
    z-index: 1;
    padding: 0.925rem 0;
    .dz-media{
        margin-right: 0.938rem;
    }
    .user-info{
        .name{
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 0.2rem;
        }
    }
    span{
        img{
            border-radius: $radius;
            width: 3rem;
            height: 3rem;
            object-fit: cover;
        }
    }
    
    .indox {
        margin-left: auto;
        a{
            width: 3rem;
            height: 3rem;
            border-radius: $radius;
            display: block;
            color: var(--primary);
            display: flex;
            justify-content: center;
            align-items: center;
            @include transitionMedium;
            position: relative;
            svg{
                path{
                   fill: var(--primary);
                }
            }
            &::before{
                content: '';
                display: block;
                width:  0.625rem;
                height: 0.625rem;
                border-radius: 50%;
                position: absolute;
                top: -2px;
                right: 0;
                border: 1px solid $white;                
            }
           
        }

        
    }
	
	&:hover{
		.indox{
			a{
            background-color: var(--rgba-primary-1);
				svg{
					path{
						fill: var(--primary);
					}
				}
                  
                &::before{
                    background-color: var(--primary);
                }
            
			}
		}
	}
}

a.btn.dlab-load-more{
    padding: 0.69375rem 1.5rem;
    font-weight: 500;
}
// -------tags
.tags{
    border-top-left-radius: 0 ;
    border-bottom-left-radius: 0;
    // border-left: 1px solid $bg-light;
    box-shadow: none;
   
   

    .tag {
        display: inline-block;
        padding: 0.625rem 0.875rem;
        margin-bottom: 0.626rem;
        margin-right: 0.313rem;
        background: rgba($main-color,0.1);
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: $radius;
        transition: 0.5s;
        color: $main-color;
        @include transitionMedium;
        &:hover{
            background: rgba($main-color,0.3);
         }
    }
    &::after{
        display: none;
    }
}
// -------slider---
.slide-img{
    width: 55px;
    height: 55px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
    }
}
.swiper-button-next,
 .swiper-button-prev {
   width: 2.5rem;
   height: 2.5rem;
   border-radius: $radius;
   background-color: var(--primary);
   top: -30px;
  
   &::after{
       display: none;
   }
} 
 .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	right: 54px;
    left: auto;
	@include respond ('phone'){
		right: 45px;
	}
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	right: 0px;
	left: auto;
}
.wallet-bar.active{

} 

// ----Upgrade---
.Upgrade{
    h4{
        color: $success;
        margin-bottom: 0.2rem;
    }
    div#redial {
        margin-top: -1rem;
    }
	@include respond ('phone'){
		.card-body{
			flex-flow: wrap;
			.donut-chart-sale{
				margin:auto;
			}
			.upgread-stroage{
				margin: auto;
				text-align: center;
				@include respond ('phone'){
					padding-bottom:14px;
				}
			}
		}
	}
}
.p-static{
	@include respond ('phone'){
		display:none;
	}
}

.mail-img{
    position: absolute;
    bottom: 0%;
    right: 0; 
}

// ------------server_status-----

.server-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-bottom: 1.125rem;
    .progress{
        width: 80%;
        height: 8px;
        background-color: $white;
    }
    .value{
        font-size: 0.875rem;
        font-weight: 400;
    }
    .progress-bar{
        position: relative;
        z-index: 1;
        overflow: unset;
        &::before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            min-width: 160%;
            opacity: 0.4;
            border-radius: 25px;
            z-index: -1;
        }
        &::after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            min-width: 220%;
            opacity: 0.4;
            border-radius: 25px;
            z-index: -1;
        }
        &.bg-pink{
            &::before{
                background-color: $pink;
            }
            &::after{
                background-color: $pink;
            }
        }
        &.bg-primary{
            &::before{
                background-color: var(--primary);
            }
            &::after{
                background-color: var(--primary);
            }
        }
        &.bg-secondary{
            &::before{
                background-color: $secondary;
            }
            &::after{
                background-color: $secondary;
            }
        }
        &.bg-success{
            &::before{
                background-color: $success;
            }
            &::after{
                background-color: $success;
            }

        }
        &.bg-danger{
            &::before{
                background-color: $danger;
            }
            &::after{
                background-color: $danger;
            }
        }
    }

}

.sidebar-footer {
    background-color:var(--rgba-primary-1);
    padding: 1.5rem;
    margin-bottom:0rem;
    .sidebar-info {
        h5{
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 0;        
        }
        h4{
            font-size: 0.875rem;
            font-weight: 600;
           a{
                color: var(--primary);
            }    
        }
    }
}
// ----- porject-Statistic---
.crypto-chart{
   .default-select{
        margin-right: 1.85rem;
    }
    .progress {
        height: 0.5rem;
    }
    .progress-content{
        h6{
            margin-bottom:0;
            font-weight: 400;
            color: #666666;
        }
        .pull-end{
            font-size: 0.875rem;
            font-weight: 600;
            color: var(--primary);
        }
    }
    .custome-tooltip{
        .apexcharts-tooltip.apexcharts-theme-light{
            width: 6.563rem;
        }
        .apexcharts-xaxistooltip { 
            color: #ffffff;
            background: var(--primary);
            border: 1px solid var(--rgba-primary-1);
            border-radius: 0.625rem;
           
        }
        .apexcharts-xaxistooltip-bottom:after {
            border-bottom-color: var(--primary);
        }
    }
    
}
.custome-tooltip{
    .apexcharts-tooltip.apexcharts-theme-light{
          
            font-size: 1.125rem;
            background-color: var(--primary);
            border-radius: $radius;
            border: none;
            overflow: unset;
            &::before{
                content: '';
                display: none;
                background-color: var(--primary);
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                top: 90%;
                left: 50%;
                transform: rotate(45deg) translateX(-50%);
                z-index: -1;
            }
        }
    .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
        display: none;
    }
    .apexcharts-tooltip-series-group.apexcharts-active {
        justify-content: center;
        font-size: 1.125rem;
        color: $white;
    }
    .apexcharts-tooltip-text {
        font-size:  0.875rem !important;
    }
    .apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
        opacity: 1;
        display: none;
    }

}
.card.statistic{
    .custome-tooltip{
        .apexcharts-tooltip.apexcharts-theme-light{ 
            background-color: $warning;
            &::before{
                content: '';
                background-color: $warning;
                width: 1rem;
                height: 1rem;
            }
        }
        .apexcharts-tooltip-series-group.apexcharts-active {
            color: $main-color;
        } 
    }       
}


    .search-coundry{
        margin-right: 1.125rem;
		height: 3rem;
		border-radius: 0.625rem;
		.filter-option-inner-inner{
			img{
				margin-right: 7px;
				width: 24px;
				margin-top: -3px;
				@include custommq($max:100rem){
					margin-top:0;
				}
			}
		}
		.dropdown-item{
			padding: 0.5rem 0.65rem;
			img{
				width:25px;
			}
		}
		.bootstrap-select .btn{
			padding: 11px 14px;
			@include custommq($max:100rem){
				padding: 8px 14px;
			}
			@include respond ('laptop'){
				padding: 8px 14px;
			}
		}
		@include respond ('phone'){
			display:none;
		}
		.bootstrap-select>.dropdown-toggle:after{
			margin-top: 0px;
			margin-left: 0.655em;
		}
	}
            


// ************
// animation
// ************

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}
// ---------user---reviews---
.user_reviews {
    padding: 1.125rem 0;
    @include respond("tab-land"){
        padding: 1.125rem 0;
    }
}


// ***************************
// -------bootstrap-page-----
// ***************************

.element-area{
    display: flex;
    .demo-view{
        width: calc(100% - 270px);
		@include custommq($max:100rem){
			width: calc(100% - 236px);
		}	
        @include respond("tab-land"){
            width: calc(100% - 224px)
        }
        @include respond('tab-port'){
            width: 100%;
        }
        @include respond ('phone-land') {
			width: 100%;
			.container-sm{
				padding:0;
			}
		}   
		
    }
    .demo-right{
        width:16.875rem;
        position: sticky;
        top: 7rem;
        height: calc(100% - 140px);
        z-index: 2;
        @include respond("tab-land"){
            width: 15.875rem;
        }
        @include respond('tab-port'){
          display: none;
        }
        @include respond('phone-land') {
            display: none;    
        }
       
    }
    .demo-right.sticky{
        top: 100px;
        transition: 0.5s;
    }
}

.demo-right-inner {
    padding: 1rem 1.625rem;
    background-color: $white;
    border-radius: $radius;
    height: 100%;
    overflow: hidden;
    h4{
        padding: 0.625rem 0;
    }
    li{
        font-size: 1rem;
        a{
            display: block;
            padding: 0.875rem 0.250rem;
        }
    }
}
.fc .fc-toolbar.fc-header-toolbar {
    flex-wrap: wrap;
	margin-bottom:0;
   
}
.fc .fc-button-group {
    margin-bottom: 0.625rem;
}

.drop-select {
    .drop-select-btn {
        padding-left: 1.125rem;
        padding-right: 1rem;
        line-height: 2.3rem;
        height: 100%;
        padding-top: 0;
        background-color: #f3f3f3;
        font-weight: 400;
        border-radius: 0.5rem;
        border:1px solid #D5DFE7;
        i {
            transition: all 0.5s;
            margin-left: 10px;
            font-size: 1rem;
            
        }
        &.style-2 {
            line-height: 2.813rem;
            align-items: center;
            i {
                float: right;
                line-height: inherit;
            }
            
        }
        
        &.style-light{
            color: #fff;
            border-color: var(--primary);
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    .dropdown-menu {
		border: 0;
		padding: 0;
		box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.10);
        min-width: 8.5rem;
        overflow: hidden !important;
		&:after {
			content: none;
		}
		.dropdown-item {
			font-size : 0.875rem;
			&:hover{
				background-color: #e9ecef !important;
			}
            &:first-child {
                color: var(--primary);
                background: var(--rgba-primary-1);
            }
		}
		
	}
	&.show {
		.drop-select-btn {
			i{
				transform: rotate(-180deg);
			}
		}	
	}
}

    
.react-datepicker{
    border: unset;
    border-radius: 0.625rem;
    box-shadow: 0 0 16px 0px rgba(0 ,0 ,0 ,0.09);
    padding: 10px 0px;
    font-family : 'poppins', sans-serif;
    &__header{
        border: unset;
        background-color: #fff;
        .react-datepicker__day-names{
            color: #717579;
            margin-bottom: -20px;
        }
    }
    .react-datepicker__triangle{
        &:before{
            box-shadow: 0 0 16px 0px rgba(0 ,0 ,0 ,0.09);
            border-bottom-color: transparent;    
        }
    }
    &__navigation{
        top: 14px;
        line-height: 20px;
    }
    &__navigation--previous{
        left: 12px;
    }
    &__navigation--next{
        right: 12px;
    }
    &__navigation-icon{
        &:before{
            border-color: #a19c9c;            
        }
    }
    &__current-month{
        padding-bottom: 10px;
        margin: 0 20px;
        color: #333;
        font-weight: 500;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: $primary;
        color: #fff;
        position: relative;
        border-radius: 0.625rem;
    }
    .react-datepicker__day--selected:before{
        content: '' !important;
        display: block;
        bottom: unset;
        right: 0;
        border-radius: 50%;
        border: none;
        background-color: #2a353a;
        width: 12px;
        height: 12px;
        border: 2px solid #fff;
        position: absolute;
    }
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 42px;
    height: 40px;
    line-height: 40px;
    font-size: 0.938rem;
    font-weight: 500;
    color: #999;
    margin: 0;
}

.calender-picker {
    .react-datepicker-popper {
        transform: translate(10px, 50px) !important;
    }
}
.gpw{
    padding: 6px;
}

.custom-react-select{
	& > div:nth-child(4){
		& > div{
			background-color: #fff;
			& > div{
				background-color: #fff;
				cursor: pointer;
				@include transitionMedium;
				&:hover{
					background-color: var(--rgba-primary-1);
					color: #000;
				}
			}
		}
	}
	& > div {
		&:nth-child(3){
			height: 3rem;
			background-color: #fff;
			border-radius: .5rem;
			border-color: #e9e2f8!important;
			//box-shadow: 0px 7px 15px 0px rgba(61, 70, 83, 0.1)!important;
			@include respond('laptop') {
				height: 2.5rem;
			}
		}
		&:nth-child(4){
			border-radius: 10px;
			min-width: 150px;
			//border: 2px solid var(--primary);
			overflow: hidden;
			box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1);
		}
		& > div{
			&:first-child{
				div{
					font-size: 0.875rem;
					color: #6e6e6e;
				}
			}
			&:nth-child(2){
				& > span{
					display: none;
				}
			}
		}
	}
	
}

.navbar-nav{
    cursor: pointer;
	.active{
		.scroll{
			color:var(--primary);
			font-weight:500;			
		}
		&:after{
			background-color:var(--primary);
		}		
	}
	.scroll{
		&.active{
			color:var(--primary);
			font-weight:500;			

			&:after{
				background-color:var(--primary);
			}
		}
	}

}