:root{
	--nav-headbg: #D5DFE7;
	--sidebar-bg: #E6EBEE;
	--headerbg: #E6EBEE;
}

//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-nav-headerbg="#{$name}"][data-theme-version="dark"],
    [data-nav-headerbg="#{$name}"] {
        @if $name != "color_1"  {
			--nav-headbg: #{$color};
			// --sidebar-bg: #{$color};
			.nav-header{
				.brand-logo{
					.logo-abbr{
						path{
							fill:$white;
						}
					}
					.brand-title{
						svg{
							path{
								fill:$white;
							}
						}
					}
					
				}
				.hamburger .line{
					background:$black;
				}
			}
		}
	}
}
//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-sidebarbg="#{$name}"][data-theme-version="dark"],
    [data-sidebarbg="#{$name}"] {
        @if $name != "color_1"  {
			--sidebar-bg: #{$color};
			.dlabnav{
				border-color:$color;		
			}
			.dlabnav .metismenu > li:after{
				background:$white!important;
			}
			.menu-toggle .dlabnav .metismenu li > ul{
				background:$color!important;
			}
			.dlabnav .metismenu ul a:before{
				background:rgba($white,0.5);
			}
			.dlabnav .header-info2{
				border-color:rgba($white,0.2)!important;		
			}
			
			&[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav {
					.metismenu{
						&>li{
							&.mm-active{
								&>a{
									i{
										color:$white;
									}
								}
							}
						}				
					} 
				}
			}
			&[data-sidebar-style="mini"],
			&[data-sidebar-style="modern"],
			&[data-sidebar-style="compact"],
			&[data-sidebar-style="icon-hover"][data-layout="horizontal"],
			&[data-sidebar-style="full"][data-layout="horizontal"]
			{
				.dlabnav{
					.metismenu li ul{
						background-color: lighten($color: $color, $amount: 10%);
					}
				} 
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:rgba($white,0.15)!important;
				}
			}
			/* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
			&[data-sidebar-style="compact"][data-layout="vertical"]{
				.dlabnav {
					.metismenu {
						&>li {
							a{
								&:hover{
									color:$white;
								}
							}
							&>a{
								&>i{
									background: lighten($color: $color, $amount: 3%)!important;
									color:rgba($white,0.7);
								}
							}
							&.mm-active,&:hover{
								&>a {
									box-shadow:none;
									background:transparent!important;
									color:$white!important;
									i{
										background: lighten($color: $color, $amount: 10%)!important;
										color:$white!important;
									}
								}
							}
						}
					}
				}
			}
			.dlabnav {
				.metismenu{
					a{
						color:rgba($white,0.8)!important;
					}
					li{
						ul{
							a{
								&:hover,
								&:focus,
								&.mm-active {
									color:$white;
								}
							}
						}
					}
					&>li{
						&>a{
							color:rgba($white,0.85)!important;
							i{
								color:rgba($white,0.85)!important;
							}
						}
						&.mm-active{
							&>a{
								background:rgba($white,0.15)!important;
								color:$white!important;
								i{
									color:$white!important;
								}
								&:before{
									background:$white!important;
									}
							}
						}
					}
					.has-arrow:after{
						border-color: rgba($white,0.85) transparent transparent rgba($white,0.85)!important;
					}					
				} 
				.header-info2 span{
					color: $white;
				}
				.sidebar-info{
					color: $white; 
					i{
						color: $white; 		
					}
				}
			}
			.plus-box{
				background-color: lighten($color: $color, $amount: 10%);
			}
			.copyright{
				color:$white;
			}
		}
	}
}

//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-headerbg="#{$name}"][data-theme-version="dark"],
    [data-headerbg="#{$name}"] {
        @if $name != "color_1"  {
			--headerbg: #{$color};
				.header.is-fixed{
					border-color:lighten($color: $color, $amount: 10%)!important;
				}
				.header{
					border:0;
				}
				.header-left{
					.search-area{
						border-color:lighten($color: $color, $amount: 10%)!important;
						.form-control,
						.input-group-text{
							background: lighten($color: $color, $amount: 10%);
							color:$white;
							svg{
								path{
									fill:$white;
								}
							}
						}
						.form-control::placeholder{
							color:$white;
						}
					}
					
				}
				.hamburger svg{
					rect{
						fill:$white;
					}
				}
				
				.header-right{
					.notification_dropdown{
						.nav-link{
							background-color: lighten($color: $color, $amount: 10%);
							border-color: lighten($color: $color, $amount: 10%);
							svg{
								path{
									fill:$white;
								}
							}
							i{
								color:$white;
							}
							&:before{
								background-color:$white;
							}
						}
					}
					.search-coundry{
						background-color: lighten($color: $color, $amount: 10%);
						border-color: lighten($color: $color, $amount: 10%);
						.default-select.dashboard-select{
							background-color: lighten($color: $color, $amount: 10%);
							color:$white;
						}
						.default-select .dropdown-toggle::after{
							border-color:$white;
						}
					}
				
					.header-profile2{
						.dropdown-menu{
							a{
								span{
									color:$black;
								}
							}
						}
					}
					.sidebar-info h4, .sidebar-info .h4,
					.header-profile2 span{
						color:$white;
				
					}
					.bootstrap-select .btn{
						background-color:lighten($color: $color, $amount: 10%)!important;
						color:$white!important;	
						border-color:lighten($color: $color, $amount: 10%)!important;
						
					}
					.bootstrap-select.countrypicker{
						border-color:lighten($color: $color, $amount: 10%)!important;
					}
					.header-drop .header-drop-toggle{
						background-color:lighten($color: $color, $amount: 10%);
						color:$white;
						border-color:lighten($color: $color, $amount: 10%);

					}					
					
				}
			
			.hamburger .line{
				background:$white!important;
			}
		}
	}
}