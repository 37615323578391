/////////////////
// Header
/////////////////
.header {
    z-index: 1;
    position: relative;
    padding: 0rem;
    background: var(--headerbg);
    z-index: 3;
    padding-left: 17rem;
    transition: all .2s ease;
	background-position: center;
    background-size: cover;
    border-bottom: 1px solid $border-light;
    box-sizing: border-box;
	.is-fixed{
		background:#3736af;
	}
	
    .header-content {
		height: 5rem;
		padding-left: 5.5rem;
		padding-right: 0rem;
		align-items: center;
		display: flex;
		left: 16.2rem;
		position: unset;
		top: 0;
		width: calc(100% - var(--width));
		margin-left:var(--width);
      
        border-top: none;
        padding-right: 2rem;
		
        @include respond ('laptop') {
			padding-left: 5rem;
            width: calc(100% - 0rem);
            margin-left: 0rem;
            height: 100%;
		}
		@include custommq ($max:64rem){
			left: 6.3rem; 
			width: calc(100% - 0rem);
			height: 5rem;
		}
        @include custommq($max: 47.9375rem) {
            padding-left: 3.75rem;
			padding-right: 0.938rem;
        }
		 @include respond ('phone-land') {
			left: 0rem;
			width: calc(100% - 0.3rem);
		 }
		
    }
    .navbar {
        padding: 0;
        height: 100%;
        width: 100%;
        .navbar-collapse {
            height: 100%;
            width: 100%;
        }
    }
	
}

////////////////////////
// CSS Pulse Effect
////////////////////////
@mixin circle($circleSize) {
    width: $circleSize;
    height: $circleSize;
    border-radius: $circleSize/2;
}
@for $i from 2 through 15{
	[data-headerbg="color_#{$i}"] .header{
		background:var(--headerbg);
		
	}
}
@for $i from 2 through 15{
	[data-headerbg="color_#{$i}"] .header.is-fixed{
		background:var(--headerbg);
		
	}
}
@for $i from 2 through 15{
	[data-nav-headbg="color_#{$i}"] .nav-header{
		background:var(--nav-headbg);
		
	}
}


/* pulse in SVG */

svg.pulse-svg {
    overflow: visible;
    .first-circle {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation: pulse-me 3s linear infinite;
        animation: pulse-me 3s linear infinite;
        fill: var(--primary);
    }
    .second-circle {
        @extend .first-circle;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    .third-circle {
        @extend .first-circle;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
    }
}


/* pulse in CSS */

.pulse-css {
    @include circle(1rem);
    border-radius: 3.5rem;
    height: 1.25rem;
    position: absolute;
    background: #fe8630;
    right: 0.375rem;
    top: 0.3125rem;
	border:0.25rem solid $white;
    width: 1.25rem;
    &:after,
    &:before {
        content: '';
        @include circle(1rem);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -.2rem;
        background-color: #D8B9C3;
        margin: auto;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation: pulse-me 3s linear infinite;
        animation: pulse-me 3s linear infinite;
        @at-root [direction="rtl"] & {
            left: auto;
            right: -.2rem;
        }
    }
	@include respond ('laptop'){
		height:1rem;
		width:1rem;
	}
}

@-webkit-keyframes pulse-me {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes pulse-me {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

[data-sidebar-style="full"],
[data-sidebar-style="overlay"] {

    .header {
        width: 100%;
		padding-left:0;
        @include respond ('laptop') {
            width: 100%;
            padding-left: 17rem;
        }
        @include custommq($max: 63.9375rem) {
            width: 100%;
            padding-left: 0rem;
        }
		/* .page-titles{
			padding-left: 20.3rem;
		} */
    }
}

[data-sidebar-style="mini"] {
    .header {
        width: 100%;
		height:5rem;
		padding-left: 6.32rem;
		@include custommq($max: 63.9375rem){
			padding-left: 6rem;
		}
    }
}

[data-sidebar-style="compact"] {
    .header {
        width: 100%;
        padding-left: 1.75rem;
		.header-content{
			padding-left: 2.5rem;
			left: 1.75rem;
			width: auto;
			margin-left: 9.2rem;
			//width: calc(100% - 11.1rem);
		}
    }
}

[data-header-position="fixed"] {
    .header {
        position: fixed;
        top: 0;
        width: 100%;
    }
    .content-body {
		padding-top:5rem;
    }
    .dlabnav {
        margin-top: 0;
    }
}
[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] {
    .header {
        width: 74.9375rem;
    }
}
[data-sidebar-style="modern"] {
    .header {
		width: 100%;
		padding-left: 0rem;
    

    }
	&[data-layout="horizontal"] {
		.nav-header .brand-logo{
		    justify-content: start;
		}
		.header .header-content{
            padding-left: 1.875rem;
			
        }
	}
}

// --------sticky-navbar-------
.header.is-fixed {
    position: fixed;
    background: $body-bg;
	@include transitionMedium;
	/*     -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    animation: 0.95s ease 0s normal forwards 1 running headerSlideDown; */
	
}
@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

.sidebar-info{
    h4{
        font-size: 0.875rem;
        font-weight: 700;
        white-space: nowrap;
    }
}
.header-drop {
    .header-drop-toggle {
        padding: 11px 14px;
        border: 1px solid #D5DFE7;
        font-weight: 400;
        background-color: $white;
        line-height: 1.5;
        border-radius: 0.625rem;
        img {
            margin-right: 7px;
        }
        i {
            margin-left: 0.655em;
        }
    }
}