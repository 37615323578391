.header-right {
    height: 100%;
	align-items: center;
	
	
    .nav-item {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-link {
            color: $l-ctd;
           
        }
    }
	.right-sidebar{
		margin-right:-1.875rem;
		a{
			height: 5rem;
			width: 5rem;
			text-align: center;
			justify-content: center;
			display: flex;
			align-items: center;
			border-left: 0.0625rem solid $light;
		}
	}
    &>li {
        &:not(:first-child) {
			@include respond('tab-land') {
				padding-left:0rem;
			}
			@include respond('phone-land') {
				padding-left:0;
			}
        }
    }
    .notification_dropdown {
        @include respond('phone-land') {
            position: static;
        }
        .nav-link {
            position: relative;
            color: var(--primary);
			border-radius: $radius;
			background-color: $bg-color;
			width:3rem;
			height: 3rem;
			line-height: 1;
			margin-right: 1.125rem;
			border: 1px solid $b-color;
			display: flex;
			justify-content: center;
			align-items: center;
			@include custommq($max:112rem){
				margin-right: 0.625rem;
			}
			 @include respond('laptop') {
				padding:0.625rem;
				margin-right: 0.625rem;
			 }
			  @include respond('phone') {
				padding: 0.325rem;
				margin-right: 0.8rem!important;
			  }
			
            i {
                font-size: 1.5rem;
				color: #666666;
				@include respond('laptop') {
					font-size: 1.125rem;
				}
				
            }
			svg{
				@include respond('laptop') {
					width:1.75rem;
					height:1.75rem;
				}
				@include respond('phone') {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
            .badge {
                position: absolute;
                font-size: 0.625rem;
                border-radius: 50%;
                right: 5px;
				top: 0px;
				font-weight: normal;
				height: 25px;
				width: 25px;
				line-height: 16px;
				text-align: center;
				padding: 4px;
				font-size: 11px;
					
				@include respond('laptop') {
					height: 1.25rem;
					width: 1.25rem;
					border-width: 0.125rem;
					line-height: 0.4375rem;
					font-size: 0.5625rem;
				}
				@include respond('phone') {
					height: 1.125rem;
					width:1.125rem;
					padding: 0;
					line-height: 0.9375rem;
					right: 0rem;
					top: -0.125rem;
				}
            }
			&.menu-wallet{
				svg{
					g path{
							fill: #666666;
					}

					
				}
				@include custommq($max:105rem){
					display:none;
				}
				
			}
        }
        .dropdown-item {
            &:focus,
            &:active {
                a {
                    color: $white;
                }
            }
            a {
                color: $dark;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .dropdown-menu {
        border-width:0;
        box-shadow: 0 0 2.3125rem rgba(8,21,66,0.05);
        @at-root [data-theme-version="dark"] & {
            box-shadow: none;
        }
    }
	//search-area
	.search-area{
		width: 19.75rem;
		border-radius:0.5rem;
		.form-control{
			height: 3rem;
			border-right:0;
			border-color: #EEEEEE;
			&:hover,
			&:focus{
				border-color:#EEEEEE;	
			}
		}	
		.input-group-text{
			height        : 3rem;
			border-radius : 0.5rem;
			background    : transparent;
			padding       :  0 1.25rem;
			border-color: #EEEEEE;
			i{
				font-size : 1.5rem;
			}
		}
		@include custommq ($max:100rem){
			width: 15.625rem;
		}
		@include respond ('tab-land'){
			display:none;
		}
	}
	.invoices-btn{
		@include respond ('phone'){
			display:none;	
		}	
	}
}
.dlab-fullscreen{
	#icon-minimize{
		display:none;
	}
	&.active{
		#icon-full{
			display:none;
		}
		#icon-minimize{
			display:inline-block;
		}
	}
}
.notification_dropdown {
    .dropdown-menu-end {
        min-width: 19.375rem;
        padding: 1rem 0 1rem;
        top: 100%;
		
        .notification_title {
            background: var(--primary);
            color: $white;
            padding: 0.625rem 1.25rem;
            h5 {
                color: $white;
                margin-bottom: 0.1875rem;
            }
        }
        .media {
			width: 2.8125rem ;
			height: 2.8125rem ;
			font-size: 1.125rem ;
			
            // &:last-child{
            //     border-bottom: 0rem;
            // }
            @at-root [data-theme-version="dark"] & {
                border-color: $d-border;
            }
            &>span {
                width: 2.1875rem;
                height: 2.1875rem;
                border-radius: 3.125rem;
                display: inline-block;
                padding: 0.4375rem 0.5625rem;
                margin-right: 0.625rem;
				@at-root [direction="rtl"]#{&} {
					 margin-right: 0;
					 margin-left: 0.625rem
				}
                &.success {
                    background: $success-light;
                    color: $success;
                }
                &.primary {
                    background: var(--rgba-primary-1);
                    color: var(--primary);
                }
                &.danger {
                    background: $danger-light;
                    color: $danger;
                }
            }
            .notify-time {
                width: 100%;
                margin-right: 0;
                color: $l-ctl;
            }
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 12.5rem;
                margin-bottom: 0;
                margin-top: 0.3125rem;
				@include respond('phone') {
				    max-width: 6.25rem;
				}
            }
        }
        .all-notification {
            display: block;
            padding: 0.9375rem 1.875rem 0;
            // color: $dark;
            text-align: center;
			border-top: 0.0625rem solid $b-color;
				
            i {
                margin-left: 0.625rem;
            }
        }
    }
	
}
	.header-profile {
        &>a.nav-link {
			padding:0;
			display: flex;
			align-items: center;
			margin-left: 0.9375rem;	
            i {
                font-weight: 700;
            }
			.header-info{
				padding-left: 0rem;
				text-align: left;
				@include respond('laptop') {
					padding-left: 0.625rem;
				}
				@include respond('phone') {
					display:none;
				}
				span{
					font-size:1rem;
					color:$black;
					display: block;
					font-weight: 600;
				}
				strong{
					color:$dark;
				}
				small{
					display:block;
					font-size: 0.8125rem;
					color:$muted;
					font-weight:400;
					line-height: 1.2;
				}
			}
			@include respond ('laptop'){
				margin-left: 0rem;
				padding-left:0rem;
				.header-info{
					span{
						font-size:1rem;
					}
				}
			}
			@include respond ('phone'){
				margin-left: 0rem;
				padding-left:0rem;
				border-left:0;
			}
        }
        .dropdown-menu {
            padding: 0.9375rem 0;
            min-width: 12.5rem;
            a {
                &:hover,
                &:focus,
                &.active {
                    color: var(--primary);
                }
            }
        }
        img {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: $radius;
			@include respond('laptop') {
				 width: 2.4375rem;
				height: 2.4375rem;
			}
        }
        .dropdown-toggle {
            i {
                font-size: 1.25rem;
            }
            span {
                @include respond('phone') {
                    display: none;
                }
            }
        }
        .profile_title {
            background: $primary;
            color: $white;
            padding: 0.625rem 1.25rem;
            h5 {
                color: $white;
                margin-bottom: 0.1875rem;
            }
        }
        // .dropdown-toggle::after{
        //     display: none;
        // }
        .dropdown-item {
            padding: 0.5rem 1.5rem;
        }
    }
	.dropdown.header-profile2{
		.nav-link{
			margin-left: 1rem;
			padding-right: 0;
			@include respond('laptop') {
				margin-left: 0rem;
			}
		}
	}


@keyframes icon-bounce{

	100%, 0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-5px);
	}
	80% {
		transform: translateY(5px);
	}
}