
// Basic Input

.form-control{
    background: $white;
    color: $dark;
	line-height: $input-line-height;
    font-size: 1rem;
	border-radius:$radius;
    &:hover,&:focus,&.active{
        box-shadow: none;
        background: $white;
        color: $dark;
    }
	&:focus{
		border-color:var(--primary);
		box-shadow: 0 0 0 0.125rem var(--rgba-primary-2);
	}
	&.solid{
		background:$body-bg;
		border-color:#ececec;
		color:#000;
		&[type="file"]{
			padding: 6px 10px;
		}
	}
}
.bootstrap-select .btn{
	padding:$input-padding-y $input-padding-x;
	border-color:$input-border-color;
	border-width:$input-border-width;
    line-height: $input-line-height;
}

.input-rounded {
    border-radius: 6.25rem;
}


// Input Color
[data-theme-version="light"],
[data-theme-version="dark"] {
	.input-primary{
		.form-control{
			border-color:var(--primary);
		}	
		.input-group-text{
			background-color:var(--primary);
			color:$white;
		}
	}
	.input-danger{
		.form-control{
			border-color:$danger;
		}	
		.input-group-text{
			background-color:$danger;
			color:$white;
		}
	}
	.input-info{
		.form-control{
			border-color:$info;
		}	
		.input-group-text{
			background-color:$info;
			color:$white;
		}
	}
	.input-success{
		.form-control{
			border-color:$success;
		}	
		.input-group-text{
			background-color:$success;
			color:$white;
		}
	}
	.input-warning{
		.form-control{
			border-color:$warning;
		}	
		.input-group-text{
			background-color:$warning;
			color:$white;
		}
	}
}
// Input Outline
[data-theme-version="light"],
[data-theme-version="dark"] {
	.input-primary-o{
		.form-control{
			border-color:var(--primary);
			
		}	
		.input-group-text{
			background-color:transparent;
			border-color:var(--primary);
			color:var(--primary);
		}
	}
	.input-danger-o{
		.form-control{
			border-color:$danger;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$danger;
			color:$danger;
		}
	}
	.input-info-o{
		.form-control{
			border-color:$info;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$info;
			color:$info;
		}
	}
	.input-success-o{
		.form-control{
			border-color:$success;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$success;
			color:$success;
		}
	}
	.input-warning-o{
		.form-control{
			border-color:$warning;
		}	
		.input-group-text{
			background-color:transparent;
			border-color:$warning;
			color:$warning;
		}
	}
}

// Input Size/
// .form-control{
    // min-height: 2.5rem;
// }
// .form-control-sm{
//     min-height: 2.25rem;
// }
// .form-control-lg{
//     min-height: 3.25rem;
// }



// Input Group
.input-group-text{
    min-width: 3.125rem;
	justify-content: center;
	border-radius: 0.5rem;
	font-weight: 600;
    font-size: 1rem;
}
.form-file-label{
    height: 2.5rem;
	padding: 0.5rem 0.75rem;
}
.input-group-prepend .btn,
.input-group-append .btn {
    z-index: 0;
}
.custom-select{
    background: none;
    // height: 2.8125rem;
    border-color: $border;
    color: $dark;
    // border-radius: 0rem
    &:focus{
        box-shadow: none;
        border-color: var(--primary);
        color: var(--primary);
    }
}
.form-file-label{
    // height: 2.8125rem;
    // border-radius: 0rem
	background: #656C73;
	white-space: nowrap;
	color: $white;
	@at-root [data-theme-version="dark"] & {
		background: $d-border;
		border-color: $d-border;
		color: $body-color;
	}
}

.custom_file_input {
    .input-group-prepend, 
    .input-group-append {
        // height: 2.8125rem;
    }

    .form-file-label::after {
        height: 100%;
        // line-height: 2.4;
    }
}


.form-control:disabled,
 .form-control[readonly] {
    background: $white;
    opacity: 1;
}

.form-file{
	border: 0.0625rem solid $border;
    background: $white;
	@at-root [data-theme-version="dark"] & {
        background: $d-bg;
		border-color: $d-border;
    }
}
.input-group{
	> .form-control-plaintext,
	  > .form-select,
	  > .form-file {
		position: relative; // For focus state's z-index
		flex: 1 1 auto;
		// Add width 1% and flex-basis auto to ensure that button will not wrap out
		// the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
		width: 1%;
		margin-bottom: 0;

	  }
	/* &> .form-file {
		display: flex;
		align-items: center;

		&:not(:last-child) .form-file-label,
		&:not(:last-child) .form-file-label::after { @include border-right-radius(0); }
		&:not(:first-child) .form-file-label { @include border-left-radius(0); }
	  } */
}


.select2-container--default 
.select2-selection--multiple
 .select2-selection__choice{
	border-radius:$radius;
}

.form-file{
	.form-control{
		margin:0;
		border-radius:0;
		border:0;
		height:auto;
	}
}
#validationCustomUsername{
	border-radius: 0 0.5rem 0.5rem 0;
}
.show-pass{
	border-radius: 0 0.5rem 0.5rem 0!important;
}
.form-file{
	.form-control{
		margin:0;
		border-radius:0;
		border:0;
		height:auto;
	}
}
.input-group{
	.input-group-text + .form-file{
		border-radius:0 0.5rem 0.5rem 0;
		.form-control{
			border-radius: inherit;
			margin:0;
		}
	}
}

.custom_file_input{
	.form-file{
		border-radius:0.5rem;
		.form-control{
			margin:0;
			border:0;
			line-height: 2.125rem;
			border-radius: inherit;
			font-size: 16px;
			font-weight: 600;
		}
	}
	.form-control{
		padding-left: 12px;
		line-height: 45px;
	}
}
#username{
	border-radius:0 0.5rem 0.5rem 0;
}
.form-control-lg{
	min-height: 3.75rem;
	padding: 0.5rem 1.1rem;
    font-size: 1.09375rem;
    border-radius: 0.5rem;
	height: calc(1.5em + 1rem + 2px);
}
.form-control-sm{
	min-height:2.5rem;
   padding: 0.25rem 1.1rem;
    font-size: 0.76563rem;
    border-radius: 0.5rem;
	height: calc(1.5em + 0.5rem + 2px);
}
.ck.ck-editor__main>.ck-editor__editable{
	background:lighten($dark, 55%)!important;
}
.modal-grid{
	background:$body-bg;
}
.nice-select.wide.form-control-lg{
	line-height: 2.7125rem;
}
.nice-select.wide.form-control-sm{
	line-height: 1.8125rem;
}
.inline-check{
	line-height: 1.8rem!important;
}
.form-check{
	font-size: 14px;
    font-weight: 600;
}
.input-full{
	border:0;
}
.border-1{
	border-radius: 0.5rem 0 0 0.5rem!important;
}
.border3{
	border-radius:0 0.5rem 0.5rem 0!important;
}
.custome-input{
	.input-group-text{
		border-color:transparent!important;
	}
}
.form-control-sm[type="file"]{
    line-height: 32px;
    padding: 4px;
    padding-left: 8px;
}
.form-control-lg[type="file"]{
	line-height:42px;
	padding-left:10px;
}