//demo styles
table#example{
    padding: 0rem 0 2rem 0;
}
table.dataTable{
    font-size: 14px;
	border-radius: $radius;
    overflow: hidden;
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}



table.dataTable thead th, table.dataTable thead td{
    border-bottom: 2px solid $border-color;
    border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td{
    border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td{
    background: transparent !important;
}

table.dataTable thead th{
    color: $white;
	white-space:nowrap;
	font-size: 1rem;
	text-transform:capitalize;
    font-weight: 500;
	padding: 20px 15px;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
	@include respond ('laptop'){
		font-size:16px;
	}
	@include respond ('phone'){
		padding: 14px 15px;
	}
}
td.doller {
	color: var(--primary);
}

table.dataTable tbody td{
    padding: 1.25rem 1.125rem;
	font-size:15px;
	font-weight: 500;
	border-bottom:0;
	white-space: nowrap;
	@include respond('phone') {
		padding: 8px 5px;
	}
	@include respond ('laptop'){
		font-size:14px;
		padding:8px 5px;
	}
}

table.dataTable tr.selected{
    color: var(--primary);
}

table.dataTable tfoot th{
    color: $dark;
    font-weight: 600;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
}
/* .dataTables_wrapper .dataTables_paginate .paginate_button{
	border-radius: 12px;
    padding: 12px 20px;
	background:$primary-light;
	color:$primary;
	&:focus,
	&:active,
	&:hover{
		color: $white !important;
		background: $primary;
		border: 0 !important;
		box-shadow: none;
	}
	&.current{
		color: $white !important;
		background: $primary;
		border: 0 !important;
	}
	&.current:hover,
	&.previous:hover,
	&.next:hover{
		background: $primary;
		color: $white !important;
	}
} */


.dataTables_wrapper .dataTables_paginate{
	align-items: center;
    display: inline-flex;
	flex-flow:wrap;
	.paginate_button.previous,.paginate_button.next{
		margin-left: 0px;
		font-size: 18px;
		//margin:0 2px;
		border: 0!important;
		height:45px;
		width: 155px;
		padding: 0;
		border-radius:0.5rem;
		line-height:45px;
		display:inline-block;
		background:	transparent;
		
		&:last-child{
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 5px 0 5px;
		}
		&:first-child{
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		i{
			font-size: 20px;
			padding: 0 10px;
		}
		span{
			border: none;
		}
		&.current:hover,
		&.previous:hover,
		&.next:hover{
			color:$white!important;
			background:var(--primary)!important;
			border-radius: $radius;
		}
		&.disabled{
			color:$main-color!important;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		@include respond ('tab-port'){
			height:45px;
			width:135px;
			font-size: 16px;
			i{
				font-size: 16px;
			}
		}
		@include respond ('phone'){
			width: 45px;
			font-size: 14px;
			height:40px;
			line-height:40px;
			i{
				font-size: 14px;
			}
			span{
				display: none;
			}
		}
		
	}
	span{
		.paginate_button{
			height:45px;
			width:45px;
			padding:0;
			margin-left: 0px;
			line-height:45px;
			text-align:center;
			font-size:18px;
			border-radius:0rem;
			color:$body-text!important;
			border:0!important;
			@include respond ('phone'){
			
				height:40px;
				width:40px;
				line-height:40px;
			}
			&.current,&:hover{
				color: $white!important;
				background:var(--primary)!important;
				box-shadow:0px 0px 5px rgba($black,0.1);
				&:hover{
					color:$white!important;
					background:var(--primary)!important;
				}
			}
		}
	}
}

.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid $b-color !important;
        padding: .3rem 0.5rem;
        color: $main-color;
        border-radius: 0.625rem  !important;
		margin-left: 14px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border!important;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		.bootstrap-select{
			width: 80px!important;
			margin: 0 5px;
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}


.rounded-lg{
	min-width:30px;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}

.dataTablesCard{
	background-color:$white;
	border-radius:$radius;
	overflow: hidden;
	&.border-no{
		td{
			border-top:0!important;
		}
	}
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: left;
    }
}
td.sorting_1{
	img{
		height: 2.188rem;
		object-fit: cover;
	}
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
	color: $body-color !important;
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter, 
.dataTables_wrapper .dataTables_processing, 
.dataTables_wrapper .dataTables_paginate {
    color: $body-color;
	border-radius:1rem;
	margin-bottom:20px;
	@include respond ('phone'){
		margin-bottom:8px;
	}
}
.paging_simple_numbers.dataTables_paginate {
	border: 0.025rem  solid var(--primary);	
	background:transparent;
	border-radius: 0.5rem;
	span{
		
		border-radius: $radius;
	}
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
	color: $body-color !important;
}

.dataTables_info{
	font-size:1rem;
	font-weight:500;
	text-align:start!important;
	display: inline-block;
}

table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: $border;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}

.dataTables_wrapper{
	.dataTables_length{
		.bootstrap-select{
			.dropdown-toggle{
				font-size: 0.813rem !important;
				padding: 0.625rem 1rem;
			}
		}
	}
}
.table-responsive {
	thead {
		background-color:var(--primary);
	}
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
 .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 0.5rem;
	line-height: 40px;
	margin: 0 5px;
	text-align: center;
	margin-left: 0;
	@include respond ('phone'){
		height: 36px;
		width: 36px;
		line-height: 36px;
	}
}
.dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 40px;
    width: 40px;
    padding: 0;
    line-height: 40px;
    display: inline-block;
	margin: 0 5px;
	border-radius: $radius;
	@include respond ('phone'){
		height: 36px;
		width: 36px;
		line-height: 36px;
		font-size:14px;
	}
}
.paging_simple_numbers.dataTables_paginate {
    border: none;
	float:right;
}

.dataTables_scrollBody thead{
	display:none;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after{
	content:"";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    background-image: none;
    margin-left: 5px;
    font-size: calc(100% - 2px);
    opacity: 0.5;
}
table.dataTable thead .sorting:after {//both
    content:"\f0dc";
}
table.dataTable thead .sorting_asc:after {//top
    content:"\f0de";
	opacity: 1;
}
table.dataTable thead .sorting_desc:after {//bottom
    content:"\f0dd";
	opacity: 1;
}


.dlab-scroll{
    overflow-y: scroll;
	//scrollbar-width: thin;
	//scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}
/* .dlab-scroll::-webkit-scrollbar {
	width: 5px;
	opacity:0;
}
.dlab-scroll::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.0); 
}
.dlab-scroll:hover::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.1); 
} */


body > *{
   // overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}
::-webkit-scrollbar {
	width: 5px;
	opacity:0;
}
/* ::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.0); 
} */
::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.1); 
}
#example2_wrapper{
	#example2_filter{
		float:left;
	}
}
.dataTables_info{
	padding: 1.25rem 0.75rem;
}